/*
 * The following CSS importing code was provided by the vendor:
 * source: https://rsms.me/inter/#features/tnum
*/
@import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
}

/*
 * MuiAutocomplete doesn't have an override key on the theme object,
 * so necessary changes are made here
 */
.MuiAutocomplete-inputRoot .MuiAutocomplete-popupIndicator,
.MuiAutocomplete-inputRoot .MuiAutocomplete-clearIndicator {
    /* This component already has 2px padding added in class .MuiAutocomplete-popupIndicator. Because of that I only add 6px of margin-right. */
    margin-right: 6px;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-popupIndicator:hover,
.MuiAutocomplete-inputRoot .MuiAutocomplete-clearIndicator:hover {
    background-color: transparent;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-popupIndicator .MuiIconButton-label svg,
.MuiAutocomplete-inputRoot .MuiAutocomplete-clearIndicator .MuiIconButton-label svg {
    width: 20px;
    color: #6B7280; /* text.secondary, grey[500] */
}

.MuiFormLabel-root.Mui-error + .MuiAutocomplete-inputRoot {
    caret-color: #B91C1C; /* error main */
    border-color: #FCA5A5; /* red[300] */
    box-shadow: 0 0 0 4px #FEE2E2; /* error light */
}
